<template>
  <div class="error error--block">
    <p>{{ $t('common.form.connection_error') }}</p>
    <p>{{ $t('common.form.try_again') }}</p>
  </div>
</template>

<style lang="scss" scoped>
.error--block {
  padding: 20px;
  margin-bottom: 24px;
  background-color: var(--red-100);
  border-radius: var(--border-radius-base);

  p:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>
